@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  /* margin-left: 3pc; */
  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin-top: 20px;
    /* max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px; */

    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      /* margin-bottom: 40vh; */
      margin-left: 0 !important;
    }
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: white;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8); /* Updated */
  composes: animation;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 3pc;
    max-width: 100vw;
  }
}

.heroMainTileMobile{
  @apply --marketplaceHeroTitleFontStyles;
  color: white;
  text-shadow: 0 0.1rem 1rem #000;/*old shadow metrics 0 0.1rem 1rem #000;*/
  text-align: center;
  margin-bottom: 2pc;
  text-align: center;
}

.heroMainTitleHidden{
  @apply --marketplaceHeroTitleFontStyles;
  color: white;
  text-shadow: 0 0.1rem 1rem #000;/*old shadow metrics 0 0.1rem 1rem #000;*/
  /* composes: animationHide; */
  text-align: center;
  @media (--viewportMedium) {
    font-size: 3pc;
    max-width: 100vw;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  text-shadow: 0 0.1rem 1rem #000;/*old shadow metrics 0 0.1rem 1rem #000;*/
  color: white;
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}


.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 30px;
  height: 80px;
  @media (max-width: 768px){
    margin: 0;
  }

  @media (--viewportLarge) {
    padding-left: 0;
  }

  @media (max-width: 768px){
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
}

.topbarSearchWithLeftPadding {
  margin-top: 0px;
  transition: margin-top 0.2s linear;
  padding-left: 24px;

  @media (--viewportLarge) {
    padding-left: 34px;
  }

  @media (max-width: 768px){
    display: flex;
    flex-direction: column;
    /* background-color: transparent; */
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
}

/* New CSS class for the Sweepstakes announcement */
.sweepstakesAnnouncement {
  transform: translateY(10%);
  padding: 15px;
  border-radius: 8px;
  background-color: var(--matterColorPrimary); /* Using existing color variable */
  text-align: center;
  font-weight: bold;
  color: white;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
  line-height: 1.8;
  letter-spacing: 0.7px;
}

/* Font size change for h3 within .sweepstakesAnnouncement */
.sweepstakesAnnouncement h3 {
  font-size: 64px;
  line-height: 0.95;

  @media (max-width: 904px){
  transform: translateY(10%);
  }

  @media (max-width: 767px){
    font-size: 40px;
    margin-top: 0px;
    }
}

.sweepstakesAnnouncement a {
  color: white;
  text-decoration: none; /* Optional: removes the underline */
  font-weight: bold;
  letter-spacing: 0.7px;
}
/* Animation for the sweepstakes announcement */
.sweepstakesAnnouncement {
  composes: animation;
  animation-delay: 0.8s;
}
