.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.rowList{
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.rowItem{
  padding: 2px 0;
  margin-right: 15px;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
