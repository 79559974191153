.carousel .control-next.control-arrow:before {
    border-left: 18px solid black !important;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
}

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    bottom: 0 !important;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 18px solid black !important;
}

.carousel.carousel-slider .control-arrow {
    margin-bottom: 10px !important;
}