@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;
   

    @media (--viewportMedium) {
      margin-left: 50px;
    }
  }
}

.text {
  text-align: justify;
}

.subtitle {
  padding-left: 40px;

  @media screen and (max-width:768px) {
    padding-left: 10px;
  }
}
