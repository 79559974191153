@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.subLabel {
  font-weight: 100;
  color: var(--matterColorAnti);
  font-size: 12px;
  line-height: 14px;
}

/* Add this new CSS class for the Sweepstakes announcement box */
.sweepstakesBox {
  margin-top: 20px;
  padding: 15px;
  border: 2px solid #ffaa00; /* Updated border color */
  border-radius: 8px;
  background-color: var(--matterColorPrimary); /* Using existing color variable */
  text-align: center;
  font-weight: bold;
 
}

.sweepstakesBox h3 {
  font-size: 30px;
}