.field {
  margin: 0;
}

.wrapper {
  margin: 20px 0;
}

.title {
  margin: 0;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
}
