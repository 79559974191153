.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 33px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &:hover {
    background-color: var(--successColor);
  }
}

.wrapperSelected {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 33px;
  padding: 5px 10px;
  background-color: var(--successColor);
  border: none;
  border-radius: 0;
  border-bottom-right-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
