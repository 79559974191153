@import '../../styles/propertySets.css';

.wrapper {
  display: flex;
  align-items: flex-end;
  min-width: 200px;
}

.rating {
  & svg {
    color: var(--marketplaceColor);
  }
}

.reviewsAvgWrapper {
  display: flex;
  align-items: center;
}

.label {
  white-space: nowrap;
}
