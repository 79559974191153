@import '../../styles/propertySets.css';

.root {
  /* display: flex;
  flex-direction: column; */

  @media(max-width: 768px){
    width: 95%;
  }
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;
  border: 1px solid var(--marketplaceColor);
  padding-left: 10px;

  @media screen and (max-width:768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 90%;
    padding-left: 10px;
  }


  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  margin: 0 auto;

}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.button {
  background-color: var(--marketplaceColor);
}