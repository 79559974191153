@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.littleBox {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  max-width: 150px;
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  cursor: pointer;
  flex-wrap: wrap;

  @media screen and (max-width:768px) {
    height: 100px;
    width: 45%;
    /* margin: 10px; */
  }
}

.littleBox:hover {
  transform: scale(1.05);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.title {
  text-align: center;
  padding-bottom: 40px;

  @media screen and (max-width:768px) {
    padding-bottom: 10px;
  }
}

.wrapper2 {
  margin-top: 5pc;

  @media screen and (max-width:768px) {
    margin-top: 10px;
  }
}

