/* @import '../../styles/propertySets.css'; */

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  /* Same box-shadow for consistency */
  padding: 25px;

  @media screen and (min-width: 1200px) {
    margin-right: 25px;
    margin-top: 25px;
    /* Half of the total 50px gap */
  }

  @media screen and (min-width: 1200px) and (max-width: 1250px) {
    margin-left: 25px;
    margin-top: 25px;
    /* increases the margin-left by 25px */
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }
}

.input {
  max-width: 220px;
  width: 100%;
  border: 1px solid gray;
  padding-left: 10px;
  background-color: white;

  @media screen and (max-width:768px) {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

.button {
  border-radius: 30px;
  padding: 0 10px;
  background-color: var(--marketplaceColor);
  background: linear-gradient(to bottom, rgb(0, 100, 150), rgb(0, 80, 130));
  color: white;
  border: none;
  cursor: pointer;

  @media screen and (max-width:768px) {
    padding: 10px;
  }
}

.button:hover {
  transform: scale(1.05);
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 25px;
  margin-top: 50px;
  @media screen and (max-width:768px) {
    flex-direction: column;
    margin-top: 25px;
  }
  @media screen and (max-width:520px) {
    flex-direction: column;
    margin-top: 25px;
  }
}

.text {
  max-width: 650px;
  width: 100%;
  text-align: justify;
}

.complementaryBox {
  display: flex;
  flex-direction: column;
  background: rgb(245, 245, 245);  /* A bit lighter than the original box */
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);  /* Same box-shadow for consistency */
  padding: 25px;
  float: right;  /* For positioning on the right side in desktop mode */
  max-width: 550px;  /* Optional, set as per your layout requirements */

  /* Prevents the box from becoming too small */
  margin-top: 25px;

  @media screen and (min-width: 1200px) {
    margin-left: 25px;
    /* Other half of the total 50px gap */
  }

  @media screen and (min-width: 1200px) and (max-width: 1250px) {
    margin-right: 25px;
    /* increases the margin-right by 25px */
  }
}



.imageBox {
  display: flex;
  flex-direction: column;
  background: rgb(0, 100, 150) no-repeat padding-box;
  background: linear-gradient(to bottom, rgb(0, 100, 150), rgb(0, 80, 130));
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 400px;
  margin: auto;  /* Center the box */
  align-items: center;
  justify-content: center;
  

  @media screen and (min-width: 768px) {
    max-width: 550px;
  }

}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;  /* Sets the gap between images */
  width: 100%;
  height: 100%;
}

.horizontalImage,
.verticalImage {
  object-fit: cover;
}

.horizontalImage {
  width: calc(100% - 5px);  /* Subtracting the gap */
  height: calc(50% - 2.5px);  /* Subtracting half the gap */
}

.verticalImage {
  width: calc(50% - 2.5px);  /* Subtracting half the gap */
  height: calc(50% - 2.5px);  /* Subtracting half the gap */
}

@media screen and (max-width: 768px) {
  .imageBox {
    width: 100%;
    height: auto;
  }

  .horizontalImage,
  .verticalImage {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
}


.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

@media screen and (max-width:768px) {
  .title1 {
    text-align: center;
    margin-top: 20px;
  }

  .title {
    text-align: center;
  }
}

.successText {
  margin: 0;
  line-height: 22px;
  color: var(--successColor);
}

/*CSS for the Giveaway box and button*/

.sweepstakesBox {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack children vertically */
  max-width: 50%;
  height: 300px;
  background: linear-gradient(to bottom, #f2f2f2, #ffffff); /* Added gradient */
  margin: auto; /* Center the box */
  text-align: center;
  min-height: 250px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.sweepstakesBox {
  margin-top: 25px;
}

@media (max-width: 1250px) {
  .sweepstakesBox {
    max-width: 550px;
      }
}

/* Modified: Added this media query to set the width to 100% for smaller screens */
@media (max-width: 768px) {
  .sweepstakesBox {
    width: 100%;
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    justify-content: space-around;
  }
}


.sweepstakesLink {
  text-decoration: none;
  color: white;
  display: contents;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.sweepstakesLink:hover {
  background-color: #e6e6e6;
  color: blue; /* Change to standard blue when hovered */
}

.sweepstakesBox h2 {
  margin: 0;
  padding: 25px;
  font-size: 40px;
  text-align: center;
  line-height: 1.25;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .sweepstakesBox h2 {
    font-size: 30px;
    line-height: 1.25;
  }
}

@media (max-width: 480px) {
  .sweepstakesBox h2 {
    font-size: 24px;
    line-height: 1.25;
  }
}

.sweepstakesBox p {
  font-size: 14px;
  text-align: center;
}

.buttongiveaway {
  border-radius: 30px;
  padding: 0 10px;
  background-color: var(--marketplaceColor);
  background: linear-gradient(to bottom, rgb(0, 100, 150), rgb(0, 80, 130));
  color: white;
  border: none;
  cursor: pointer;
  height: 40px;  /* Set a specific height */

  @media screen and (max-width:768px) {
    padding: 10px;
  }
}

.buttongiveaway:hover {
  transform: scale(1.05);
}

/* New CSS class for the Sweepstakes announcement */
.sweepstakesAnnouncement1 h3{
  position: relative;
  border-radius: 8px;
  background-color: var(--matterColorPrimary); /* Using existing color variable */
  text-align: center;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.7px;
  font-size: 48px;

  @media (max-width: 768px){
  margin-top: 15px;
  margin-bottom: 0px;
  padding: 5px;
  position: relative;
  transform: translateY(00%);
  text-shadow: none;
  font-size: 32px;
  }
}